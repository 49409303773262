import { api } from './graphApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

/** AAD configuration data */
export type AadConfig = {
  __typename?: 'AadConfig';
  /** AAD admin group object ID */
  adminGroupObjectId: Scalars['String'];
  /** AAD application ID */
  clientId: Scalars['String'];
  /** AAD application secret - we are showing only first 3 characters from secret */
  clientSecret: Scalars['String'];
  /** AAD integration enabled */
  isEnabled: Scalars['Boolean'];
  /** Last processing message */
  lastProcessingMessage: Scalars['String'];
  /** AAD tenant ID */
  tenantId: Scalars['String'];
};

/** Data necessary for changing AAD configuration  */
export type AadConfigData = {
  /** AAD admin group object ID */
  adminGroupObjectId: Scalars['String'];
  /** AAD application ID */
  clientId: Scalars['String'];
  /** AAD application secret */
  clientSecret: Scalars['String'];
  /** AAD integration enabled */
  isEnabled: Scalars['Boolean'];
  /** AAD tenant ID */
  tenantId: Scalars['String'];
};

/**
 * Definition of the access rules for a single entity in the mesh.
 * One entity can eventually have multiple accesses, e.g. for test and prod environments.
 */
export type Access = {
  __typename?: 'Access';
  /** List of additional hostnames for the entity */
  additionalHostnames: Array<Scalars['String']>;
  /** Date and time of the last modification to this access */
  changed: Scalars['DateTime'];
  /** Base64 encoded configuration data for this access */
  config?: Maybe<Scalars['String']>;
  /** Optional description of the access definition */
  description?: Maybe<Scalars['String']>;
  /** Device Access connection`s metadata */
  deviceInfo?: Maybe<AccessDevice>;
  /** Fully Qualified Domain Name of the entity */
  fqdn: Scalars['String'];
  /** Firewall configuration */
  fwConfig: FwConfig;
  /** Groups that the entity is a member of */
  groups: Array<Group>;
  /** Numeric ID */
  id: Scalars['Int'];
  /** IP address assigned to the entity */
  ipAddress: Scalars['String'];
  /** Optional access listeners allowing communication forwarding (access box) */
  listeners: Array<AccessListener>;
  /** Name */
  name: Scalars['String'];
  /** Setting for nebula communication - punch back mode */
  punchBack: Scalars['Boolean'];
  /** Setting for nebula communication - work on restrictive network */
  restrictiveNetwork: Scalars['Boolean'];
  /** Access connection`s statistics */
  statistics?: Maybe<AccessStatistic>;
  /** Date and time from which this access definition is valid in the mesh */
  validFrom: Scalars['DateTime'];
  /** Date and time after which this access definition is no longer valid in the mesh */
  validTo: Scalars['DateTime'];
};

/** Access device info. */
export type AccessDevice = {
  __typename?: 'AccessDevice';
  /** Last contact from device - login message */
  contacted?: Maybe<Scalars['DateTime']>;
  /** Device Id for communication with shieldoo */
  deviceId: Scalars['String'];
  /** Device OS */
  deviceOS: Scalars['String'];
  /** Device OS type - [windows,darwin,linux,android,ios,synology] */
  deviceOSType: Scalars['String'];
  /** Device client software version */
  deviceSWVersion: Scalars['String'];
  /** Device name */
  name: Scalars['String'];
  /** OS Auto update statistics */
  osAutoUpdate?: Maybe<OsAutoUpdate>;
};

/** Data necessary for creating users' device access and info. */
export type AccessDeviceData = {
  /** Last contact from device - login message */
  contacted?: InputMaybe<Scalars['DateTime']>;
  /** Device Id for communication with shieldoo */
  deviceId: Scalars['String'];
  /** Device OS - full name */
  deviceOS: Scalars['String'];
  /** Device OS type - [windows,darwin,linux,android,ios,synology] */
  deviceOSType: Scalars['String'];
  /** Device client software version */
  deviceSWVersion: Scalars['String'];
  /** Device name */
  name: Scalars['String'];
};

/** Access listener defines rules for communication forwarding (access box) */
export type AccessListener = {
  __typename?: 'AccessListener';
  /** Mapping to AccessListenerType */
  accessListenerType?: Maybe<AccessListenerType>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Name of the host to which the communication is to be forwarded */
  forwardHost?: Maybe<Scalars['String']>;
  /** Port to which the communication is to be forwarded */
  forwardPort?: Maybe<Scalars['Int']>;
  /** Port on which the server is listening */
  listenPort?: Maybe<Scalars['Int']>;
  /** Communication protocol */
  protocol?: Maybe<Scalars['String']>;
};

/** Data necessary for creating an access listener */
export type AccessListenerData = {
  /** ID of access listener type */
  accessListenerTypeId: Scalars['Int'];
  /** Description */
  description: Scalars['String'];
  /** Name of the host to which the communication is to be forwarded */
  forwardHost: Scalars['String'];
  /** Port to which the communication is to be forwarded */
  forwardPort: Scalars['Int'];
  /** Port on which the server is listening */
  listenPort: Scalars['Int'];
  /** Communication protocol */
  protocol: Scalars['String'];
};

/** Access listener type defines type of connected device */
export type AccessListenerType = {
  __typename?: 'AccessListenerType';
  /** Glyph - for mapping to icon */
  glyph: Scalars['String'];
  /** Numeric ID */
  id: Scalars['Int'];
  /** Name / description of item */
  name: Scalars['String'];
};

/** Access statistic. */
export type AccessStatistic = {
  __typename?: 'AccessStatistic';
  /** Nebula tunnel is connected to lighthouse */
  isConnectd?: Maybe<Scalars['Boolean']>;
  /** Communication over WebSocket underlay tunnel - communication over restrictive network */
  isOverRestrictiveNetwork?: Maybe<Scalars['Boolean']>;
  /** Last contact from client */
  lastContact?: Maybe<Scalars['DateTime']>;
  /** Last contact from client from now in seconds */
  lastContactFromNow?: Maybe<Scalars['Int']>;
};

/** Admin dashboard data */
export type AdminDashboard = {
  __typename?: 'AdminDashboard';
  /** Number of invited users */
  invitedUsers: Scalars['Int'];
  /** Number of servers */
  servers: Scalars['Int'];
  /** User connection statistics */
  userStatistics: Array<Maybe<UserStatistic>>;
  /** Number of users */
  users: Scalars['Int'];
};

/** CLI API configuration data */
export type CliApiConfig = {
  __typename?: 'CliApiConfig';
  /** CLI API Key - we are showing only first 3 characters from key when querying, full key returned when setting */
  apiKey: Scalars['String'];
  /** CLI API integration enabled */
  isEnabled: Scalars['Boolean'];
  /** CLI API URL */
  url: Scalars['String'];
};

/** Data necessary for changing CLI API configuration  */
export type CliApiConfigData = {
  /** CLI API integration enabled */
  enabled: Scalars['Boolean'];
};

/** Code list item. */
export type CodeListItem = {
  __typename?: 'CodeListItem';
  /** Numeric ID */
  id: Scalars['Int'];
  /** Name of the record */
  name: Scalars['String'];
};

/** Config data */
export type ConfigItem = {
  __typename?: 'ConfigItem';
  /** Integration with AAD or other identity platform enabled */
  identityImportEnabled: Scalars['Boolean'];
  /** Max certificate validity - max value for ValidTo for access */
  maxCertificateValidity: Scalars['DateTime'];
  /** Max DB records */
  maxDBRecords: Scalars['Int'];
  /** Tenant ID */
  tenantId: Scalars['String'];
  /** Full tenant name */
  tenantName: Scalars['String'];
};

/** Cost usage item. */
export type CostUsageItem = {
  __typename?: 'CostUsageItem';
  /** Cost */
  cost: Scalars['Float'];
  /** Hours spend in the mesh */
  hours: Scalars['Float'];
  /** Is user (false means server) */
  isUser: Scalars['Boolean'];
  /** UPN */
  upn: Scalars['String'];
  /** YearMonth */
  yearMonth: Scalars['String'];
};

/** Cost usage month item. */
export type CostUsageMonthItem = {
  __typename?: 'CostUsageMonthItem';
  /** Cost */
  cost: Scalars['Float'];
  /** Cost usage items for resources in mesh */
  costUsageItems: Array<CostUsageItem>;
  /** YearMonth */
  yearMonth: Scalars['String'];
};

/** Entity in the mesh; It may be a User or a Server. */
export type Entity = {
  /** Optional description of the entity */
  description?: Maybe<Scalars['String']>;
  /** Numeric ID */
  id: Scalars['Int'];
  /** Display name of the entity */
  name: Scalars['String'];
};

/** Firewall configuration */
export type FwConfig = Node & {
  __typename?: 'FwConfig';
  /** Date and time of the last modification to this configuration */
  changed: Scalars['DateTime'];
  /** Rules for incoming communication */
  fwConfigIns: Array<FwConfigRule>;
  /** Rules for outgoing communication */
  fwConfigOuts: Array<FwConfigRule>;
  /** Numeric ID */
  id: Scalars['Int'];
  /** Friendly name of the configuration */
  name?: Maybe<Scalars['String']>;
};

/** Data necessary for creating a new or updating an existing firewall configuration */
export type FwConfigData = {
  /** Rules for incoming communication */
  fwConfigIns: Array<FwConfigRuleData>;
  /** Rules for outgoing communication */
  fwConfigOuts: Array<FwConfigRuleData>;
  /** ID of the configuration if it already exists */
  id?: InputMaybe<Scalars['Int']>;
  /** Friendly name of the configuration */
  name?: InputMaybe<Scalars['String']>;
};

/** Firewall rule */
export type FwConfigRule = {
  __typename?: 'FwConfigRule';
  /** Groups to / from which the communication is allowed if host is set to "group"  */
  groups?: Maybe<Array<Group>>;
  /** Remote hostname, "any" or "group"  */
  host: Scalars['String'];
  /** Port to / from which the communication is comming */
  port: Scalars['String'];
  /** Communication protocol */
  proto: Scalars['String'];
};

/** Data necessary for creating a firewall config rule */
export type FwConfigRuleData = {
  /** Groups to / from which the communication is allowed if host is set to "group"  */
  groups?: InputMaybe<Array<GroupData>>;
  /** Remote hostname, "any" or "group"  */
  host: Scalars['String'];
  /** Port to / from which the communication is comming */
  port: Scalars['String'];
  /** Communication protocol */
  proto: Scalars['String'];
};

/** Group of entities. */
export type Group = Node & {
  __typename?: 'Group';
  /** Optional description of the group */
  description?: Maybe<Scalars['String']>;
  /** Numeric ID */
  id: Scalars['Int'];
  /** Name of the group */
  name: Scalars['String'];
};

/** Data needed to create a new or update an existing group */
export type GroupData = {
  /** Optional group description */
  description?: InputMaybe<Scalars['String']>;
  /** ID of the group if it already exists */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the group */
  name: Scalars['String'];
};

/** Data needed to create a new or update an existing user */
export type InviteUserData = {
  /** Optional entity description */
  description?: InputMaybe<Scalars['String']>;
  /** ID of the user if it already exists */
  id?: InputMaybe<Scalars['Int']>;
  /** Display name of the entity */
  name: Scalars['String'];
  /** User roles */
  roles: Array<InputMaybe<Scalars['String']>>;
  /** Unique name of the entity */
  upn: Scalars['String'];
  /** ID of parent UserAccessTemplate object - if is empty than we will use default UserAccessTemplate */
  userAccessTemplateId?: InputMaybe<Scalars['Int']>;
};

/** Lighthouse entity */
export type Lighthouse = {
  __typename?: 'Lighthouse';
  /** Internal IP address in mesh */
  ipAddress: Scalars['String'];
  /** Nebula listening UDP port */
  port: Scalars['Int'];
  /** Public IP address */
  publicIp: Scalars['String'];
};

/** Log data */
export type LogItem = {
  __typename?: 'LogItem';
  /** Date and time when event was created */
  created: Scalars['DateTime'];
  /** Log data */
  data: Scalars['String'];
  /** Unique ID */
  id: Scalars['Int'];
  /** UPN generated event */
  upn: Scalars['String'];
};

/** Mutations */
export type Mutation = {
  __typename?: 'Mutation';
  /** Deletes a firewall configuration with a given ID */
  firewallConfigurationDelete: FwConfig;
  /** Creates or updates a firewall configuration */
  firewallConfigurationSave: FwConfig;
  /** Deletes a group with a given ID */
  groupDelete: Group;
  /** Creates or updates a group */
  groupSave: Group;
  /** Deletes an server with a given ID */
  serverDelete: Server;
  /** Creates or updates a server */
  serverSave: Server;
  /** Change AAD configuration */
  systemAadConfigSave: AadConfig;
  /** Change CLI API configuration */
  systemCliApiConfigSave: CliApiConfig;
  /** Change system configuration */
  systemConfigSave: SystemConfig;
  /** Deletes an access definition with a given ID */
  userAccessDelete: UserAccess;
  /** Creates or updates an access definition */
  userAccessSave: UserAccess;
  /** Deletes a UserAccessTemplate with a given ID */
  userAccessTemplateDelete: UserAccessTemplate;
  /** Creates or updates a UserAccessTemplate */
  userAccessTemplateSave: UserAccessTemplate;
  /** Deletes an user with a given ID */
  userDelete: User;
  /** Users' device create */
  userDeviceCreate: UserAccessDeviceInfo;
  /** Users' device delete - delete device info and access data */
  userDeviceDelete: Scalars['Int'];
  /** Users' device save note */
  userDeviceSaveNote: Access;
  /** Invite user */
  userInvite: User;
  /** Creates or updates a user */
  userSave: User;
};


/** Mutations */
export type MutationFirewallConfigurationDeleteArgs = {
  id: Scalars['Int'];
};


/** Mutations */
export type MutationFirewallConfigurationSaveArgs = {
  data: FwConfigData;
};


/** Mutations */
export type MutationGroupDeleteArgs = {
  id: Scalars['Int'];
};


/** Mutations */
export type MutationGroupSaveArgs = {
  data: GroupData;
};


/** Mutations */
export type MutationServerDeleteArgs = {
  id: Scalars['Int'];
};


/** Mutations */
export type MutationServerSaveArgs = {
  data?: InputMaybe<ServerData>;
};


/** Mutations */
export type MutationSystemAadConfigSaveArgs = {
  data: AadConfigData;
};


/** Mutations */
export type MutationSystemCliApiConfigSaveArgs = {
  data: CliApiConfigData;
};


/** Mutations */
export type MutationSystemConfigSaveArgs = {
  data: SystemConfigData;
};


/** Mutations */
export type MutationUserAccessDeleteArgs = {
  id: Scalars['Int'];
};


/** Mutations */
export type MutationUserAccessSaveArgs = {
  data: UserAccessData;
};


/** Mutations */
export type MutationUserAccessTemplateDeleteArgs = {
  id: Scalars['Int'];
};


/** Mutations */
export type MutationUserAccessTemplateSaveArgs = {
  data: UserAccessTemplateData;
};


/** Mutations */
export type MutationUserDeleteArgs = {
  id: Scalars['Int'];
};


/** Mutations */
export type MutationUserDeviceCreateArgs = {
  data: AccessDeviceData;
  publicKey?: InputMaybe<Scalars['String']>;
  userAccessId: Scalars['Int'];
};


/** Mutations */
export type MutationUserDeviceDeleteArgs = {
  id: Scalars['Int'];
};


/** Mutations */
export type MutationUserDeviceSaveNoteArgs = {
  id: Scalars['Int'];
  note: Scalars['String'];
};


/** Mutations */
export type MutationUserInviteArgs = {
  data: InviteUserData;
};


/** Mutations */
export type MutationUserSaveArgs = {
  data: UserData;
};

/** Base interface for nodes that can be retrieved by ID. */
export type Node = {
  /** Numeric ID */
  id: Scalars['Int'];
};

/** OS Auto update statistics data. */
export type OsAutoUpdate = {
  __typename?: 'OsAutoUpdate';
  /** OS description */
  description: Scalars['String'];
  /** Last update date */
  lastUpdate: Scalars['DateTime'];
  /** Last update output */
  lastUpdateOutput: Scalars['String'];
  /** Last update success */
  lastUpdateSuccess: Scalars['Boolean'];
  /** OS name */
  name: Scalars['String'];
  /** OS type - [windows,darwin,linux,android,ios,synology] */
  osType: Scalars['String'];
  /** Other updates */
  otherUpdates: Array<Scalars['String']>;
  /** Other updates count */
  otherUpdatesCount: Scalars['Int'];
  /** Security updates */
  securityUpdates: Array<Scalars['String']>;
  /** Security updates count */
  securityUpdatesCount: Scalars['Int'];
  /** OS version */
  version: Scalars['String'];
};

/** Queries */
export type Query = {
  __typename?: 'Query';
  /** List of access listener types */
  accessListenerTypes: Array<AccessListenerType>;
  /** Admin dashboard data */
  adminDashboard: AdminDashboard;
  /** Code list firewalls */
  codelistFirewalls: Array<CodeListItem>;
  /** Code list groups */
  codelistGroups: Array<CodeListItem>;
  /** Code list UserAccessTemplates */
  codelistUserAccessTemplates: Array<CodeListItem>;
  /** Configuration data */
  config: ConfigItem;
  /** Retrieve a firewall configuration by its ID */
  firewallConfiguration: FwConfig;
  /** List firewall configurations */
  firewallConfigurations: Array<FwConfig>;
  /** Retrieve a group by its ID */
  group: Group;
  /** List of groups */
  groups: Array<Group>;
  /** Retrieve a current user info */
  me: User;
  /** Get Month cost usage data */
  monthCostUsage: Array<CostUsageMonthItem>;
  /** Search security log */
  securityLogs: Array<LogItem>;
  /** Retrieve a concrete user by its ID */
  server: Server;
  /** List of servers */
  servers: Array<Server>;
  /** Get system Configuration data */
  systemConfig: SystemConfig;
  /** Search telemetry log */
  telemetryLogs: Array<LogItem>;
  /** Retrieve a concrete entity by its ID */
  user: User;
  /** Retrieve a UserAccessTemplate by its ID */
  userAccessTemplate: UserAccessTemplate;
  /** List of user access templates */
  userAccessTemplates: Array<UserAccessTemplate>;
  /** List of users */
  users: Array<User>;
};


/** Queries */
export type QueryAccessListenerTypesArgs = {
  name?: InputMaybe<Scalars['String']>;
};


/** Queries */
export type QueryFirewallConfigurationArgs = {
  id: Scalars['Int'];
};


/** Queries */
export type QueryFirewallConfigurationsArgs = {
  name?: InputMaybe<Scalars['String']>;
};


/** Queries */
export type QueryGroupArgs = {
  id: Scalars['Int'];
};


/** Queries */
export type QueryGroupsArgs = {
  name?: InputMaybe<Scalars['String']>;
};


/** Queries */
export type QuerySecurityLogsArgs = {
  createdFrom?: InputMaybe<Scalars['DateTime']>;
  createdTo?: InputMaybe<Scalars['DateTime']>;
  filter?: InputMaybe<Scalars['String']>;
  upn?: InputMaybe<Scalars['String']>;
};


/** Queries */
export type QueryServerArgs = {
  id: Scalars['Int'];
};


/** Queries */
export type QueryServersArgs = {
  name?: InputMaybe<Scalars['String']>;
};


/** Queries */
export type QueryTelemetryLogsArgs = {
  createdFrom?: InputMaybe<Scalars['DateTime']>;
  createdTo?: InputMaybe<Scalars['DateTime']>;
  filter?: InputMaybe<Scalars['String']>;
  upn?: InputMaybe<Scalars['String']>;
};


/** Queries */
export type QueryUserArgs = {
  id: Scalars['Int'];
};


/** Queries */
export type QueryUserAccessTemplateArgs = {
  id: Scalars['Int'];
};


/** Queries */
export type QueryUserAccessTemplatesArgs = {
  name?: InputMaybe<Scalars['String']>;
};


/** Queries */
export type QueryUsersArgs = {
  name?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
};

/** Server in the mesh (device entity) */
export type Server = Entity & {
  __typename?: 'Server';
  /** Access definition issued for this server */
  access: Access;
  /** Setting for app management - allow client to autoupdate */
  allowAutoUpdate: Scalars['Boolean'];
  /** Optional server description */
  description?: Maybe<Scalars['String']>;
  /** Numeric ID */
  id: Scalars['Int'];
  /** Unique name of the server */
  name: Scalars['String'];
  /** Server OS auto update policy */
  serverOSAutoUpdatePolicy: ServerOsAutoUpdatePolicy;
};

/** Data necessary for creating or updating an server-access definition */
export type ServerAccessData = {
  /** Additional hostnames for the entity */
  additionalHostnames: Array<Scalars['String']>;
  /** Optional description of the access definition */
  description?: InputMaybe<Scalars['String']>;
  /** ID of the firewall configuration */
  fwConfigId: Scalars['Int'];
  /** IDs of the groups that the entity is a member of */
  groupsIds: Array<Scalars['Int']>;
  /** IP address to be assigned to the entity or null if one should be taken from the pool */
  ipAddress?: InputMaybe<Scalars['String']>;
  /** Optional access listeners allowing communication forwarding (access box) */
  listeners: Array<AccessListenerData>;
  /** Setting for nebula communication - punch back mode */
  punchBack: Scalars['Boolean'];
  /** Setting for nebula communication - work on restrictive network */
  restrictiveNetwork: Scalars['Boolean'];
  /** Date and time after which this access definition is no longer valid in the mesh */
  validTo: Scalars['DateTime'];
};

/** Data needed to create a new or update an existing server */
export type ServerData = {
  /** Server access profile */
  access?: InputMaybe<ServerAccessData>;
  /** Setting for app management - allow client to autoupdate */
  allowAutoUpdate: Scalars['Boolean'];
  /** Optional entity description */
  description?: InputMaybe<Scalars['String']>;
  /** ID of the server if it already exists */
  id?: InputMaybe<Scalars['Int']>;
  /** Unique name of the server */
  name: Scalars['String'];
  /** Server OS auto update policy */
  osAutoUpdatePolicy?: InputMaybe<ServerOsAutoUpdatePolicyData>;
};

/** Servers accessed by user */
export type ServerForAccess = {
  __typename?: 'ServerForAccess';
  /** Optional description */
  description?: Maybe<Scalars['String']>;
  /** Servers' mesh IP address */
  ipAddress: Scalars['String'];
  /** Optional access listeners allowing communication forwarding (access box) */
  listeners: Array<AccessListener>;
  /** Unique name of server */
  name: Scalars['String'];
  /** Access connection`s statistics */
  statistics?: Maybe<AccessStatistic>;
};

/** Server OS auto update policy */
export type ServerOsAutoUpdatePolicy = {
  __typename?: 'ServerOSAutoUpdatePolicy';
  /** All auto update enabled */
  allAutoUpdateEnabled: Scalars['Boolean'];
  /** OS auto update enabled */
  osAutoUpdateEnabled: Scalars['Boolean'];
  /** OS auto update hour */
  osAutoUpdateHour: Scalars['Int'];
  /** Restart after update */
  restartAfterUpdate: Scalars['Boolean'];
  /** Security auto update enabled */
  securityAutoUpdateEnabled: Scalars['Boolean'];
};

/** Data needed to create a new or update an existing server OS auto update policy */
export type ServerOsAutoUpdatePolicyData = {
  /** All auto update enabled */
  allAutoUpdateEnabled: Scalars['Boolean'];
  /** OS auto update enabled */
  osAutoUpdateEnabled: Scalars['Boolean'];
  /** OS auto update hour */
  osAutoUpdateHour: Scalars['Int'];
  /** Restart after update */
  restartAfterUpdate: Scalars['Boolean'];
  /** Security auto update enabled */
  securityAutoUpdateEnabled: Scalars['Boolean'];
};

/** System Configuration attributes */
export type SystemConfig = {
  __typename?: 'SystemConfig';
  /** AAD configuration */
  aadConfig: AadConfig;
  /** CLI API configuration */
  cliApiConfig: CliApiConfig;
  /** List of lighthouses */
  lighthouses: Array<Maybe<Lighthouse>>;
  /** Maximum CA DateTime validity - Accesses cannot have validity after this date */
  maximumCertificateValidity: Scalars['DateTime'];
  /** Network mesh CIDR */
  networkCidr: Scalars['String'];
};

/** Data necessary for changing system config */
export type SystemConfigData = {
  /** CIDR */
  networkCidr: Scalars['String'];
};

/** User of the system (client entity) */
export type User = Entity & {
  __typename?: 'User';
  /** Optional user description */
  description?: Maybe<Scalars['String']>;
  /** Numeric ID */
  id: Scalars['Int'];
  /** User's name */
  name: Scalars['String'];
  /** Origin of account - from authentication - google/microsoft */
  origin?: Maybe<Scalars['String']>;
  /** User roles */
  roles: Array<Maybe<Scalars['String']>>;
  /** User's email */
  upn: Scalars['String'];
  /** UserAccess definitions issued for this user */
  userAccesses: Array<UserAccess>;
};

/**
 * Definition of the access rules for a single user in the mesh.
 * One user can eventually have multiple user-accesses, e.g. for test and prod environments.
 */
export type UserAccess = {
  __typename?: 'UserAccess';
  /** Accesses associated to this UserAccess */
  accesses: Array<Access>;
  /** Date and time of the last modification to this access */
  changed: Scalars['DateTime'];
  /** Optional description of the access definition */
  description?: Maybe<Scalars['String']>;
  /** Firewall configuration */
  fwConfig: FwConfig;
  /** Groups that the entity is a member of */
  groups: Array<Group>;
  /** Numeric ID */
  id: Scalars['Int'];
  /** Name */
  name: Scalars['String'];
  /** Servers for access - resources which can be accessed by user */
  serversForAccess: Array<ServerForAccess>;
  /** Link to parent UserAccessTemplate */
  userAccessTemplate: UserAccessTemplate;
  /** Date and time from which this access definition is valid in the mesh */
  validFrom: Scalars['DateTime'];
  /** Date and time after which this access definition is no longer valid in the mesh */
  validTo: Scalars['DateTime'];
};

/** Data necessary for creating or updating an access definition */
export type UserAccessData = {
  /** Optional description of the access definition */
  description?: InputMaybe<Scalars['String']>;
  /** ID of entity */
  entityId: Scalars['Int'];
  /** ID of the firewall configuration */
  fwConfigId: Scalars['Int'];
  /** IDs of the groups that the entity is a member of */
  groupsIds: Array<Scalars['Int']>;
  /** ID of the access if it already exists */
  id?: InputMaybe<Scalars['Int']>;
  /** Name */
  name: Scalars['String'];
  /** ID of parent UserAccessTemplate object */
  userAccessTemplateId?: InputMaybe<Scalars['Int']>;
  /** Date and time after which this access definition is no longer valid in the mesh */
  validTo: Scalars['DateTime'];
};

/** Result data for creating user access device. */
export type UserAccessDeviceInfo = {
  __typename?: 'UserAccessDeviceInfo';
  /** Access */
  access: Access;
  /** CA public key */
  caPublicKey: Scalars['String'];
  /** Certificate data */
  certificate: Scalars['String'];
  /** Lighthouse public IP */
  lighthouseIp: Scalars['String'];
  /** Lighthouse port */
  lighthousePort: Scalars['Int'];
  /** Lighthouse private IP */
  lighthousePrivateIp: Scalars['String'];
};

/** Definition of the template access rules for a user entity in the mesh. */
export type UserAccessTemplate = {
  __typename?: 'UserAccessTemplate';
  /** Date and time of the last modification to this access */
  changed: Scalars['DateTime'];
  /** Optional description of the access definition */
  description?: Maybe<Scalars['String']>;
  /** Firewall configuration */
  fwConfig: FwConfig;
  /** Groups that the entity is a member of */
  groups: Array<Group>;
  /** Numeric ID */
  id: Scalars['Int'];
  /** Name */
  name: Scalars['String'];
  /** Date and time from which this access definition is valid in the mesh */
  validFrom: Scalars['DateTime'];
  /** Date and time after which this access definition is no longer valid in the mesh */
  validTo: Scalars['DateTime'];
};

/** Data necessary for creating or updating an access definition */
export type UserAccessTemplateData = {
  /** Flag if record is deleted */
  deleted: Scalars['Boolean'];
  /** Optional description of the access definition */
  description?: InputMaybe<Scalars['String']>;
  /** ID of the firewall configuration */
  fwConfigId: Scalars['Int'];
  /** IDs of the groups that the entity is a member of */
  groupsIds: Array<Scalars['Int']>;
  /** ID of the access if it already exists */
  id?: InputMaybe<Scalars['Int']>;
  /** Name */
  name: Scalars['String'];
  /** Date and time after which this access definition is no longer valid in the mesh */
  validTo: Scalars['DateTime'];
};

/** Data needed to create a new or update an existing user */
export type UserData = {
  /** Optional entity description */
  description?: InputMaybe<Scalars['String']>;
  /** ID of the user if it already exists */
  id?: InputMaybe<Scalars['Int']>;
  /** Display name of the entity */
  name: Scalars['String'];
  /** Origin of account - from authentication - google/microsoft */
  origin?: InputMaybe<Scalars['String']>;
  /** User roles */
  roles: Array<InputMaybe<Scalars['String']>>;
  /** Unique name of the entity */
  upn: Scalars['String'];
};

/** User connection statistics items */
export type UserStatistic = {
  __typename?: 'UserStatistic';
  /** Date */
  date: Scalars['String'];
  /** Hour */
  hour: Scalars['Int'];
  /** Number of users online */
  usersOnline: Scalars['Int'];
};

export type AccessRightsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessRightsQuery = { __typename?: 'Query', me: { __typename?: 'User', userAccesses: Array<{ __typename?: 'UserAccess', name: string, serversForAccess: Array<{ __typename?: 'ServerForAccess', name: string, ipAddress: string, description?: string | null, listeners: Array<{ __typename?: 'AccessListener', listenPort?: number | null, protocol?: string | null, forwardPort?: number | null, forwardHost?: string | null, description?: string | null, accessListenerType?: { __typename?: 'AccessListenerType', glyph: string } | null }>, statistics?: { __typename?: 'AccessStatistic', isConnectd?: boolean | null, isOverRestrictiveNetwork?: boolean | null, lastContact?: any | null, lastContactFromNow?: number | null } | null }> }> } };

export type CodeListFirewallsQueryVariables = Exact<{ [key: string]: never; }>;


export type CodeListFirewallsQuery = { __typename?: 'Query', codelistFirewalls: Array<{ __typename?: 'CodeListItem', id: number, name: string }> };

export type CodeListGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type CodeListGroupsQuery = { __typename?: 'Query', codelistGroups: Array<{ __typename?: 'CodeListItem', id: number, name: string }> };

export type CodeListUserAccessTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type CodeListUserAccessTemplatesQuery = { __typename?: 'Query', codelistUserAccessTemplates: Array<{ __typename?: 'CodeListItem', id: number, name: string }> };

export type ConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigQuery = { __typename?: 'Query', config: { __typename?: 'ConfigItem', maxDBRecords: number, tenantId: string, tenantName: string, maxCertificateValidity: any, identityImportEnabled: boolean } };

export type AdminDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminDashboardQuery = { __typename?: 'Query', adminDashboard: { __typename?: 'AdminDashboard', users: number, servers: number, invitedUsers: number, userStatistics: Array<{ __typename?: 'UserStatistic', date: string, hour: number, usersOnline: number } | null> } };

export type UserDeviceCreateMutationVariables = Exact<{
  userAccessId: Scalars['Int'];
  data: AccessDeviceData;
  publicKey?: InputMaybe<Scalars['String']>;
}>;


export type UserDeviceCreateMutation = { __typename?: 'Mutation', userDeviceCreate: { __typename?: 'UserAccessDeviceInfo', certificate: string, caPublicKey: string, lighthouseIp: string, lighthousePort: number, lighthousePrivateIp: string, access: { __typename?: 'Access', id: number, name: string, ipAddress: string } } };

export type UserDeviceDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserDeviceDeleteMutation = { __typename?: 'Mutation', userDeviceDelete: number };

export type UserDeviceSaveNoteMutationVariables = Exact<{
  id: Scalars['Int'];
  note: Scalars['String'];
}>;


export type UserDeviceSaveNoteMutation = { __typename?: 'Mutation', userDeviceSaveNote: { __typename?: 'Access', id: number } };

export type FirewallDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FirewallDeleteMutation = { __typename?: 'Mutation', firewallConfigurationDelete: { __typename?: 'FwConfig', id: number } };

export type FwConfigFieldsFragment = { __typename?: 'FwConfigRule', proto: string, port: string, host: string, groups?: Array<{ __typename?: 'Group', id: number, name: string }> | null };

export type GetFirewallQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFirewallQuery = { __typename?: 'Query', firewallConfiguration: { __typename?: 'FwConfig', id: number, name?: string | null, fwConfigOuts: Array<{ __typename?: 'FwConfigRule', proto: string, port: string, host: string, groups?: Array<{ __typename?: 'Group', id: number, name: string }> | null }>, fwConfigIns: Array<{ __typename?: 'FwConfigRule', proto: string, port: string, host: string, groups?: Array<{ __typename?: 'Group', id: number, name: string }> | null }> } };

export type FirewallsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type FirewallsQuery = { __typename?: 'Query', firewallConfigurations: Array<{ __typename?: 'FwConfig', id: number, name?: string | null }> };

export type SaveFirewallMutationVariables = Exact<{
  data: FwConfigData;
}>;


export type SaveFirewallMutation = { __typename?: 'Mutation', firewallConfigurationSave: { __typename?: 'FwConfig', id: number } };

export type GroupDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GroupDeleteMutation = { __typename?: 'Mutation', groupDelete: { __typename?: 'Group', id: number } };

export type GroupQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GroupQuery = { __typename?: 'Query', group: { __typename?: 'Group', id: number, name: string, description?: string | null } };

export type GroupsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type GroupsQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: number, name: string, description?: string | null }> };

export type SaveGroupMutationVariables = Exact<{
  data: GroupData;
}>;


export type SaveGroupMutation = { __typename?: 'Mutation', groupSave: { __typename?: 'Group', id: number } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, upn: string, name: string, roles: Array<string | null>, description?: string | null, origin?: string | null, userAccesses: Array<{ __typename?: 'UserAccess', id: number, name: string, accesses: Array<{ __typename?: 'Access', id: number, ipAddress: string, description?: string | null, statistics?: { __typename?: 'AccessStatistic', isConnectd?: boolean | null, lastContactFromNow?: number | null, lastContact?: any | null } | null, deviceInfo?: { __typename?: 'AccessDevice', deviceId: string, name: string, deviceOSType: string, deviceOS: string } | null }> }> } };

export type SecurityLogsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
  upn?: InputMaybe<Scalars['String']>;
  createdFrom?: InputMaybe<Scalars['DateTime']>;
  createdTo?: InputMaybe<Scalars['DateTime']>;
}>;


export type SecurityLogsQuery = { __typename?: 'Query', securityLogs: Array<{ __typename?: 'LogItem', id: number, upn: string, data: string, created: any }> };

export type ServerAccessQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ServerAccessQuery = { __typename?: 'Query', server: { __typename?: 'Server', access: { __typename?: 'Access', config?: string | null } } };

export type ServerDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ServerDeleteMutation = { __typename?: 'Mutation', serverDelete: { __typename?: 'Server', id: number } };

export type ServerDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ServerDetailQuery = { __typename?: 'Query', server: { __typename?: 'Server', id: number, name: string, allowAutoUpdate: boolean, description?: string | null, serverOSAutoUpdatePolicy: { __typename?: 'ServerOSAutoUpdatePolicy', allAutoUpdateEnabled: boolean, osAutoUpdateEnabled: boolean, osAutoUpdateHour: number, restartAfterUpdate: boolean, securityAutoUpdateEnabled: boolean }, access: { __typename?: 'Access', ipAddress: string, description?: string | null, additionalHostnames: Array<string>, statistics?: { __typename?: 'AccessStatistic', isConnectd?: boolean | null, isOverRestrictiveNetwork?: boolean | null, lastContact?: any | null, lastContactFromNow?: number | null } | null, deviceInfo?: { __typename?: 'AccessDevice', name: string, deviceId: string, deviceOSType: string, deviceOS: string, deviceSWVersion: string, osAutoUpdate?: { __typename?: 'OsAutoUpdate', description: string, lastUpdate: any, lastUpdateOutput: string, lastUpdateSuccess: boolean, name: string, osType: string, otherUpdates: Array<string>, otherUpdatesCount: number, securityUpdates: Array<string>, securityUpdatesCount: number, version: string } | null } | null } } };

export type ServerQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ServerQuery = { __typename?: 'Query', server: { __typename?: 'Server', id: number, name: string, allowAutoUpdate: boolean, description?: string | null } };

export type ServerExpertQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ServerExpertQuery = { __typename?: 'Query', server: { __typename?: 'Server', id: number, name: string, allowAutoUpdate: boolean, description?: string | null, serverOSAutoUpdatePolicy: { __typename?: 'ServerOSAutoUpdatePolicy', allAutoUpdateEnabled: boolean, osAutoUpdateEnabled: boolean, osAutoUpdateHour: number, restartAfterUpdate: boolean, securityAutoUpdateEnabled: boolean }, access: { __typename?: 'Access', name: string, ipAddress: string, additionalHostnames: Array<string>, validFrom: any, validTo: any, changed: any, punchBack: boolean, restrictiveNetwork: boolean, groups: Array<{ __typename?: 'Group', id: number, name: string }>, fwConfig: { __typename?: 'FwConfig', id: number, name?: string | null, changed: any, fwConfigOuts: Array<{ __typename?: 'FwConfigRule', port: string, proto: string, host: string, groups?: Array<{ __typename?: 'Group', id: number, name: string, description?: string | null }> | null }>, fwConfigIns: Array<{ __typename?: 'FwConfigRule', port: string, proto: string, host: string, groups?: Array<{ __typename?: 'Group', id: number, name: string, description?: string | null }> | null }> }, listeners: Array<{ __typename?: 'AccessListener', listenPort?: number | null, protocol?: string | null, forwardPort?: number | null, forwardHost?: string | null, description?: string | null, accessListenerType?: { __typename?: 'AccessListenerType', id: number, glyph: string, name: string } | null }> } } };

export type ServersQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type ServersQuery = { __typename?: 'Query', servers: Array<{ __typename?: 'Server', id: number, name: string, allowAutoUpdate: boolean, description?: string | null, serverOSAutoUpdatePolicy: { __typename?: 'ServerOSAutoUpdatePolicy', allAutoUpdateEnabled: boolean, osAutoUpdateEnabled: boolean, osAutoUpdateHour: number, restartAfterUpdate: boolean, securityAutoUpdateEnabled: boolean }, access: { __typename?: 'Access', ipAddress: string, additionalHostnames: Array<string>, description?: string | null, statistics?: { __typename?: 'AccessStatistic', isConnectd?: boolean | null, isOverRestrictiveNetwork?: boolean | null, lastContact?: any | null, lastContactFromNow?: number | null } | null, deviceInfo?: { __typename?: 'AccessDevice', name: string, deviceId: string, deviceOSType: string, deviceOS: string, deviceSWVersion: string, osAutoUpdate?: { __typename?: 'OsAutoUpdate', description: string, lastUpdate: any, lastUpdateOutput: string, lastUpdateSuccess: boolean, name: string, osType: string, otherUpdates: Array<string>, otherUpdatesCount: number, securityUpdates: Array<string>, securityUpdatesCount: number, version: string } | null } | null } }> };

export type SaveServerMutationVariables = Exact<{
  data: ServerData;
}>;


export type SaveServerMutation = { __typename?: 'Mutation', serverSave: { __typename?: 'Server', id: number } };

export type AadConfigSaveMutationVariables = Exact<{
  data: AadConfigData;
}>;


export type AadConfigSaveMutation = { __typename?: 'Mutation', systemAadConfigSave: { __typename?: 'AadConfig', adminGroupObjectId: string, clientId: string, clientSecret: string, isEnabled: boolean, lastProcessingMessage: string, tenantId: string } };

export type CliApiConfigSaveMutationVariables = Exact<{
  data: CliApiConfigData;
}>;


export type CliApiConfigSaveMutation = { __typename?: 'Mutation', systemCliApiConfigSave: { __typename?: 'CliApiConfig', apiKey: string, isEnabled: boolean, url: string } };

export type CostsUsageQueryVariables = Exact<{ [key: string]: never; }>;


export type CostsUsageQuery = { __typename?: 'Query', monthCostUsage: Array<{ __typename?: 'CostUsageMonthItem', cost: number, yearMonth: string, costUsageItems: Array<{ __typename?: 'CostUsageItem', cost: number, hours: number, isUser: boolean, upn: string, yearMonth: string }> }> };

export type SystemConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemConfigQuery = { __typename?: 'Query', systemConfig: { __typename?: 'SystemConfig', networkCidr: string, maximumCertificateValidity: any, lighthouses: Array<{ __typename?: 'Lighthouse', ipAddress: string, port: number, publicIp: string } | null>, aadConfig: { __typename?: 'AadConfig', adminGroupObjectId: string, clientId: string, clientSecret: string, isEnabled: boolean, lastProcessingMessage: string, tenantId: string }, cliApiConfig: { __typename?: 'CliApiConfig', apiKey: string, isEnabled: boolean, url: string } } };

export type SystemConfigSaveMutationVariables = Exact<{
  data: SystemConfigData;
}>;


export type SystemConfigSaveMutation = { __typename?: 'Mutation', systemConfigSave: { __typename?: 'SystemConfig', networkCidr: string } };

export type TelemetryLogsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
  upn?: InputMaybe<Scalars['String']>;
  createdFrom?: InputMaybe<Scalars['DateTime']>;
  createdTo?: InputMaybe<Scalars['DateTime']>;
}>;


export type TelemetryLogsQuery = { __typename?: 'Query', telemetryLogs: Array<{ __typename?: 'LogItem', id: number, upn: string, data: string, created: any }> };

export type UserAccessTemplateDeleteMutationVariables = Exact<{
  userAccessTemplateDeleteId: Scalars['Int'];
}>;


export type UserAccessTemplateDeleteMutation = { __typename?: 'Mutation', userAccessTemplateDelete: { __typename?: 'UserAccessTemplate', id: number } };

export type UserAccessTemplateQueryVariables = Exact<{
  userAccessTemplateId: Scalars['Int'];
}>;


export type UserAccessTemplateQuery = { __typename?: 'Query', userAccessTemplate: { __typename?: 'UserAccessTemplate', id: number, name: string, description?: string | null, validTo: any, groups: Array<{ __typename?: 'Group', id: number, name: string, description?: string | null }>, fwConfig: { __typename?: 'FwConfig', id: number, name?: string | null } } };

export type UserAccessTemplatesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type UserAccessTemplatesQuery = { __typename?: 'Query', userAccessTemplates: Array<{ __typename?: 'UserAccessTemplate', id: number, name: string, description?: string | null, validTo: any }> };

export type UserAccessTemplateSaveMutationVariables = Exact<{
  data: UserAccessTemplateData;
}>;


export type UserAccessTemplateSaveMutation = { __typename?: 'Mutation', userAccessTemplateSave: { __typename?: 'UserAccessTemplate', id: number } };

export type UserDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserDeleteMutation = { __typename?: 'Mutation', userDelete: { __typename?: 'User', id: number } };

export type UserDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserDetailQuery = { __typename?: 'Query', user: { __typename?: 'User', id: number, upn: string, name: string, description?: string | null, origin?: string | null, roles: Array<string | null>, userAccesses: Array<{ __typename?: 'UserAccess', id: number, name: string, description?: string | null, validFrom: any, validTo: any, changed: any, groups: Array<{ __typename?: 'Group', id: number, name: string, description?: string | null }>, fwConfig: { __typename?: 'FwConfig', id: number, name?: string | null, changed: any, fwConfigOuts: Array<{ __typename?: 'FwConfigRule', port: string, proto: string, host: string, groups?: Array<{ __typename?: 'Group', id: number, name: string, description?: string | null }> | null }>, fwConfigIns: Array<{ __typename?: 'FwConfigRule', port: string, proto: string, host: string, groups?: Array<{ __typename?: 'Group', id: number, name: string, description?: string | null }> | null }> }, userAccessTemplate: { __typename?: 'UserAccessTemplate', id: number, name: string }, accesses: Array<{ __typename?: 'Access', id: number, name: string, ipAddress: string, fqdn: string, description?: string | null, changed: any, statistics?: { __typename?: 'AccessStatistic', isConnectd?: boolean | null, isOverRestrictiveNetwork?: boolean | null, lastContact?: any | null, lastContactFromNow?: number | null } | null, deviceInfo?: { __typename?: 'AccessDevice', name: string, deviceId: string, deviceOSType: string, deviceOS: string, contacted?: any | null } | null }>, serversForAccess: Array<{ __typename?: 'ServerForAccess', name: string, ipAddress: string, description?: string | null, listeners: Array<{ __typename?: 'AccessListener', listenPort?: number | null, protocol?: string | null, forwardHost?: string | null, forwardPort?: number | null, description?: string | null, accessListenerType?: { __typename?: 'AccessListenerType', id: number, glyph: string, name: string } | null }> }> }> } };

export type UserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: number, name: string, upn: string, description?: string | null, roles: Array<string | null>, origin?: string | null, userAccesses: Array<{ __typename?: 'UserAccess', name: string }> } };

export type UserInviteMutationVariables = Exact<{
  data: InviteUserData;
}>;


export type UserInviteMutation = { __typename?: 'Mutation', userInvite: { __typename?: 'User', id: number, name: string, upn: string, description?: string | null, roles: Array<string | null>, origin?: string | null, userAccesses: Array<{ __typename?: 'UserAccess', name: string }> } };

export type UsersQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, name: string, upn: string, description?: string | null, roles: Array<string | null>, origin?: string | null, userAccesses: Array<{ __typename?: 'UserAccess', name: string }> }> };

export type UserAccessDeleteMutationVariables = Exact<{
  userAccessDeleteId: Scalars['Int'];
}>;


export type UserAccessDeleteMutation = { __typename?: 'Mutation', userAccessDelete: { __typename?: 'UserAccess', id: number, name: string } };

export type UserAccessSaveMutationVariables = Exact<{
  data: UserAccessData;
}>;


export type UserAccessSaveMutation = { __typename?: 'Mutation', userAccessSave: { __typename?: 'UserAccess', id: number, name: string } };

export type UserSaveMutationVariables = Exact<{
  data: UserData;
}>;


export type UserSaveMutation = { __typename?: 'Mutation', userSave: { __typename?: 'User', id: number, name: string, upn: string, description?: string | null, roles: Array<string | null>, origin?: string | null, userAccesses: Array<{ __typename?: 'UserAccess', name: string }> } };

export const FwConfigFieldsFragmentDoc = `
    fragment FwConfigFields on FwConfigRule {
  proto
  port
  host
  groups {
    id
    name
  }
}
    `;
export const AccessRightsDocument = `
    query AccessRights {
  me {
    userAccesses {
      name
      serversForAccess {
        name
        ipAddress
        description
        listeners {
          listenPort
          protocol
          forwardPort
          forwardHost
          description
          accessListenerType {
            glyph
          }
        }
        statistics {
          isConnectd
          isOverRestrictiveNetwork
          lastContact
          lastContactFromNow
        }
      }
    }
  }
}
    `;
export const CodeListFirewallsDocument = `
    query CodeListFirewalls {
  codelistFirewalls {
    id
    name
  }
}
    `;
export const CodeListGroupsDocument = `
    query CodeListGroups {
  codelistGroups {
    id
    name
  }
}
    `;
export const CodeListUserAccessTemplatesDocument = `
    query CodeListUserAccessTemplates {
  codelistUserAccessTemplates {
    id
    name
  }
}
    `;
export const ConfigDocument = `
    query Config {
  config {
    maxDBRecords
    tenantId
    tenantName
    maxCertificateValidity
    identityImportEnabled
  }
}
    `;
export const AdminDashboardDocument = `
    query AdminDashboard {
  adminDashboard {
    users
    servers
    invitedUsers
    userStatistics {
      date
      hour
      usersOnline
    }
  }
}
    `;
export const UserDeviceCreateDocument = `
    mutation UserDeviceCreate($userAccessId: Int!, $data: AccessDeviceData!, $publicKey: String) {
  userDeviceCreate(
    userAccessId: $userAccessId
    data: $data
    publicKey: $publicKey
  ) {
    access {
      id
      name
      ipAddress
    }
    certificate
    caPublicKey
    lighthouseIp
    lighthousePort
    lighthousePrivateIp
  }
}
    `;
export const UserDeviceDeleteDocument = `
    mutation UserDeviceDelete($id: Int!) {
  userDeviceDelete(id: $id)
}
    `;
export const UserDeviceSaveNoteDocument = `
    mutation UserDeviceSaveNote($id: Int!, $note: String!) {
  userDeviceSaveNote(id: $id, note: $note) {
    id
  }
}
    `;
export const FirewallDeleteDocument = `
    mutation FirewallDelete($id: Int!) {
  firewallConfigurationDelete(id: $id) {
    id
  }
}
    `;
export const GetFirewallDocument = `
    query GetFirewall($id: Int!) {
  firewallConfiguration(id: $id) {
    id
    name
    fwConfigOuts {
      ...FwConfigFields
    }
    fwConfigIns {
      ...FwConfigFields
    }
  }
}
    ${FwConfigFieldsFragmentDoc}`;
export const FirewallsDocument = `
    query Firewalls($name: String) {
  firewallConfigurations(name: $name) {
    id
    name
  }
}
    `;
export const SaveFirewallDocument = `
    mutation SaveFirewall($data: FwConfigData!) {
  firewallConfigurationSave(data: $data) {
    id
  }
}
    `;
export const GroupDeleteDocument = `
    mutation GroupDelete($id: Int!) {
  groupDelete(id: $id) {
    id
  }
}
    `;
export const GroupDocument = `
    query Group($id: Int!) {
  group(id: $id) {
    id
    name
    description
  }
}
    `;
export const GroupsDocument = `
    query Groups($name: String) {
  groups(name: $name) {
    id
    name
    description
  }
}
    `;
export const SaveGroupDocument = `
    mutation SaveGroup($data: GroupData!) {
  groupSave(data: $data) {
    id
  }
}
    `;
export const MeDocument = `
    query Me {
  me {
    id
    upn
    name
    roles
    description
    origin
    userAccesses {
      id
      name
      accesses {
        id
        ipAddress
        description
        statistics {
          isConnectd
          lastContactFromNow
          lastContact
        }
        deviceInfo {
          deviceId
          name
          deviceOSType
          deviceOS
        }
      }
    }
  }
}
    `;
export const SecurityLogsDocument = `
    query SecurityLogs($filter: String, $upn: String, $createdFrom: DateTime, $createdTo: DateTime) {
  securityLogs(
    filter: $filter
    upn: $upn
    createdFrom: $createdFrom
    createdTo: $createdTo
  ) {
    id
    upn
    data
    created
  }
}
    `;
export const ServerAccessDocument = `
    query ServerAccess($id: Int!) {
  server(id: $id) {
    access {
      config
    }
  }
}
    `;
export const ServerDeleteDocument = `
    mutation ServerDelete($id: Int!) {
  serverDelete(id: $id) {
    id
  }
}
    `;
export const ServerDetailDocument = `
    query ServerDetail($id: Int!) {
  server(id: $id) {
    id
    name
    allowAutoUpdate
    serverOSAutoUpdatePolicy {
      allAutoUpdateEnabled
      osAutoUpdateEnabled
      osAutoUpdateHour
      restartAfterUpdate
      securityAutoUpdateEnabled
    }
    description
    access {
      ipAddress
      description
      additionalHostnames
      statistics {
        isConnectd
        isOverRestrictiveNetwork
        lastContact
        lastContactFromNow
      }
      deviceInfo {
        name
        deviceId
        deviceOSType
        deviceOS
        deviceSWVersion
        osAutoUpdate {
          description
          lastUpdate
          lastUpdateOutput
          lastUpdateSuccess
          name
          osType
          otherUpdates
          otherUpdatesCount
          securityUpdates
          securityUpdatesCount
          version
        }
      }
    }
  }
}
    `;
export const ServerDocument = `
    query Server($id: Int!) {
  server(id: $id) {
    id
    name
    allowAutoUpdate
    description
  }
}
    `;
export const ServerExpertDocument = `
    query ServerExpert($id: Int!) {
  server(id: $id) {
    id
    name
    allowAutoUpdate
    serverOSAutoUpdatePolicy {
      allAutoUpdateEnabled
      osAutoUpdateEnabled
      osAutoUpdateHour
      restartAfterUpdate
      securityAutoUpdateEnabled
    }
    description
    access {
      name
      ipAddress
      additionalHostnames
      groups {
        id
        name
      }
      fwConfig {
        id
        name
        fwConfigOuts {
          port
          proto
          host
          groups {
            id
            name
            description
          }
        }
        fwConfigIns {
          port
          proto
          host
          groups {
            id
            name
            description
          }
        }
        changed
      }
      validFrom
      validTo
      changed
      listeners {
        listenPort
        protocol
        forwardPort
        forwardHost
        accessListenerType {
          id
          glyph
          name
        }
        description
      }
      punchBack
      restrictiveNetwork
    }
  }
}
    `;
export const ServersDocument = `
    query Servers($name: String) {
  servers(name: $name) {
    id
    name
    allowAutoUpdate
    serverOSAutoUpdatePolicy {
      allAutoUpdateEnabled
      osAutoUpdateEnabled
      osAutoUpdateHour
      restartAfterUpdate
      securityAutoUpdateEnabled
    }
    description
    access {
      ipAddress
      additionalHostnames
      description
      statistics {
        isConnectd
        isOverRestrictiveNetwork
        lastContact
        lastContactFromNow
      }
      deviceInfo {
        name
        deviceId
        deviceOSType
        deviceOS
        deviceSWVersion
        osAutoUpdate {
          description
          lastUpdate
          lastUpdateOutput
          lastUpdateSuccess
          name
          osType
          otherUpdates
          otherUpdatesCount
          securityUpdates
          securityUpdatesCount
          version
        }
      }
    }
  }
}
    `;
export const SaveServerDocument = `
    mutation SaveServer($data: ServerData!) {
  serverSave(data: $data) {
    id
  }
}
    `;
export const AadConfigSaveDocument = `
    mutation AadConfigSave($data: AadConfigData!) {
  systemAadConfigSave(data: $data) {
    adminGroupObjectId
    clientId
    clientSecret
    isEnabled
    lastProcessingMessage
    tenantId
  }
}
    `;
export const CliApiConfigSaveDocument = `
    mutation CliApiConfigSave($data: CliApiConfigData!) {
  systemCliApiConfigSave(data: $data) {
    apiKey
    isEnabled
    url
  }
}
    `;
export const CostsUsageDocument = `
    query CostsUsage {
  monthCostUsage {
    cost
    costUsageItems {
      cost
      hours
      isUser
      upn
      yearMonth
    }
    yearMonth
  }
}
    `;
export const SystemConfigDocument = `
    query SystemConfig {
  systemConfig {
    networkCidr
    maximumCertificateValidity
    lighthouses {
      ipAddress
      port
      publicIp
    }
    aadConfig {
      adminGroupObjectId
      clientId
      clientSecret
      isEnabled
      lastProcessingMessage
      tenantId
    }
    cliApiConfig {
      apiKey
      isEnabled
      url
    }
  }
}
    `;
export const SystemConfigSaveDocument = `
    mutation SystemConfigSave($data: SystemConfigData!) {
  systemConfigSave(data: $data) {
    networkCidr
  }
}
    `;
export const TelemetryLogsDocument = `
    query TelemetryLogs($filter: String, $upn: String, $createdFrom: DateTime, $createdTo: DateTime) {
  telemetryLogs(
    filter: $filter
    upn: $upn
    createdFrom: $createdFrom
    createdTo: $createdTo
  ) {
    id
    upn
    data
    created
  }
}
    `;
export const UserAccessTemplateDeleteDocument = `
    mutation UserAccessTemplateDelete($userAccessTemplateDeleteId: Int!) {
  userAccessTemplateDelete(id: $userAccessTemplateDeleteId) {
    id
  }
}
    `;
export const UserAccessTemplateDocument = `
    query UserAccessTemplate($userAccessTemplateId: Int!) {
  userAccessTemplate(id: $userAccessTemplateId) {
    id
    name
    description
    groups {
      id
      name
      description
    }
    fwConfig {
      id
      name
    }
    validTo
  }
}
    `;
export const UserAccessTemplatesDocument = `
    query UserAccessTemplates($name: String) {
  userAccessTemplates(name: $name) {
    id
    name
    description
    validTo
  }
}
    `;
export const UserAccessTemplateSaveDocument = `
    mutation UserAccessTemplateSave($data: UserAccessTemplateData!) {
  userAccessTemplateSave(data: $data) {
    id
  }
}
    `;
export const UserDeleteDocument = `
    mutation UserDelete($id: Int!) {
  userDelete(id: $id) {
    id
  }
}
    `;
export const UserDetailDocument = `
    query UserDetail($id: Int!) {
  user(id: $id) {
    id
    upn
    name
    description
    origin
    roles
    userAccesses {
      id
      name
      description
      groups {
        id
        name
        description
      }
      fwConfig {
        id
        name
        fwConfigOuts {
          port
          proto
          host
          groups {
            id
            name
            description
          }
        }
        fwConfigIns {
          port
          proto
          host
          groups {
            id
            name
            description
          }
        }
        changed
      }
      validFrom
      validTo
      changed
      userAccessTemplate {
        id
        name
      }
      accesses {
        id
        name
        ipAddress
        fqdn
        description
        changed
        statistics {
          isConnectd
          isOverRestrictiveNetwork
          lastContact
          lastContactFromNow
        }
        deviceInfo {
          name
          deviceId
          deviceOSType
          deviceOS
          contacted
        }
      }
      serversForAccess {
        name
        ipAddress
        description
        listeners {
          listenPort
          protocol
          forwardHost
          forwardPort
          accessListenerType {
            id
            glyph
            name
          }
          description
        }
      }
    }
  }
}
    `;
export const UserDocument = `
    query User($id: Int!) {
  user(id: $id) {
    id
    name
    upn
    description
    roles
    origin
    userAccesses {
      name
    }
  }
}
    `;
export const UserInviteDocument = `
    mutation UserInvite($data: InviteUserData!) {
  userInvite(data: $data) {
    id
    name
    upn
    description
    roles
    origin
    userAccesses {
      name
    }
  }
}
    `;
export const UsersDocument = `
    query Users($search: String, $origin: String) {
  users(name: $search, origin: $origin) {
    id
    name
    upn
    description
    roles
    origin
    userAccesses {
      name
    }
  }
}
    `;
export const UserAccessDeleteDocument = `
    mutation UserAccessDelete($userAccessDeleteId: Int!) {
  userAccessDelete(id: $userAccessDeleteId) {
    id
    name
  }
}
    `;
export const UserAccessSaveDocument = `
    mutation UserAccessSave($data: UserAccessData!) {
  userAccessSave(data: $data) {
    id
    name
  }
}
    `;
export const UserSaveDocument = `
    mutation UserSave($data: UserData!) {
  userSave(data: $data) {
    id
    name
    upn
    description
    roles
    origin
    userAccesses {
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AccessRights: build.query<AccessRightsQuery, AccessRightsQueryVariables | void>({
      query: (variables) => ({ document: AccessRightsDocument, variables })
    }),
    CodeListFirewalls: build.query<CodeListFirewallsQuery, CodeListFirewallsQueryVariables | void>({
      query: (variables) => ({ document: CodeListFirewallsDocument, variables })
    }),
    CodeListGroups: build.query<CodeListGroupsQuery, CodeListGroupsQueryVariables | void>({
      query: (variables) => ({ document: CodeListGroupsDocument, variables })
    }),
    CodeListUserAccessTemplates: build.query<CodeListUserAccessTemplatesQuery, CodeListUserAccessTemplatesQueryVariables | void>({
      query: (variables) => ({ document: CodeListUserAccessTemplatesDocument, variables })
    }),
    Config: build.query<ConfigQuery, ConfigQueryVariables | void>({
      query: (variables) => ({ document: ConfigDocument, variables })
    }),
    AdminDashboard: build.query<AdminDashboardQuery, AdminDashboardQueryVariables | void>({
      query: (variables) => ({ document: AdminDashboardDocument, variables })
    }),
    UserDeviceCreate: build.mutation<UserDeviceCreateMutation, UserDeviceCreateMutationVariables>({
      query: (variables) => ({ document: UserDeviceCreateDocument, variables })
    }),
    UserDeviceDelete: build.mutation<UserDeviceDeleteMutation, UserDeviceDeleteMutationVariables>({
      query: (variables) => ({ document: UserDeviceDeleteDocument, variables })
    }),
    UserDeviceSaveNote: build.mutation<UserDeviceSaveNoteMutation, UserDeviceSaveNoteMutationVariables>({
      query: (variables) => ({ document: UserDeviceSaveNoteDocument, variables })
    }),
    FirewallDelete: build.mutation<FirewallDeleteMutation, FirewallDeleteMutationVariables>({
      query: (variables) => ({ document: FirewallDeleteDocument, variables })
    }),
    GetFirewall: build.query<GetFirewallQuery, GetFirewallQueryVariables>({
      query: (variables) => ({ document: GetFirewallDocument, variables })
    }),
    Firewalls: build.query<FirewallsQuery, FirewallsQueryVariables | void>({
      query: (variables) => ({ document: FirewallsDocument, variables })
    }),
    SaveFirewall: build.mutation<SaveFirewallMutation, SaveFirewallMutationVariables>({
      query: (variables) => ({ document: SaveFirewallDocument, variables })
    }),
    GroupDelete: build.mutation<GroupDeleteMutation, GroupDeleteMutationVariables>({
      query: (variables) => ({ document: GroupDeleteDocument, variables })
    }),
    Group: build.query<GroupQuery, GroupQueryVariables>({
      query: (variables) => ({ document: GroupDocument, variables })
    }),
    Groups: build.query<GroupsQuery, GroupsQueryVariables | void>({
      query: (variables) => ({ document: GroupsDocument, variables })
    }),
    SaveGroup: build.mutation<SaveGroupMutation, SaveGroupMutationVariables>({
      query: (variables) => ({ document: SaveGroupDocument, variables })
    }),
    Me: build.query<MeQuery, MeQueryVariables | void>({
      query: (variables) => ({ document: MeDocument, variables })
    }),
    SecurityLogs: build.query<SecurityLogsQuery, SecurityLogsQueryVariables | void>({
      query: (variables) => ({ document: SecurityLogsDocument, variables })
    }),
    ServerAccess: build.query<ServerAccessQuery, ServerAccessQueryVariables>({
      query: (variables) => ({ document: ServerAccessDocument, variables })
    }),
    ServerDelete: build.mutation<ServerDeleteMutation, ServerDeleteMutationVariables>({
      query: (variables) => ({ document: ServerDeleteDocument, variables })
    }),
    ServerDetail: build.query<ServerDetailQuery, ServerDetailQueryVariables>({
      query: (variables) => ({ document: ServerDetailDocument, variables })
    }),
    Server: build.query<ServerQuery, ServerQueryVariables>({
      query: (variables) => ({ document: ServerDocument, variables })
    }),
    ServerExpert: build.query<ServerExpertQuery, ServerExpertQueryVariables>({
      query: (variables) => ({ document: ServerExpertDocument, variables })
    }),
    Servers: build.query<ServersQuery, ServersQueryVariables | void>({
      query: (variables) => ({ document: ServersDocument, variables })
    }),
    SaveServer: build.mutation<SaveServerMutation, SaveServerMutationVariables>({
      query: (variables) => ({ document: SaveServerDocument, variables })
    }),
    AadConfigSave: build.mutation<AadConfigSaveMutation, AadConfigSaveMutationVariables>({
      query: (variables) => ({ document: AadConfigSaveDocument, variables })
    }),
    CliApiConfigSave: build.mutation<CliApiConfigSaveMutation, CliApiConfigSaveMutationVariables>({
      query: (variables) => ({ document: CliApiConfigSaveDocument, variables })
    }),
    CostsUsage: build.query<CostsUsageQuery, CostsUsageQueryVariables | void>({
      query: (variables) => ({ document: CostsUsageDocument, variables })
    }),
    SystemConfig: build.query<SystemConfigQuery, SystemConfigQueryVariables | void>({
      query: (variables) => ({ document: SystemConfigDocument, variables })
    }),
    SystemConfigSave: build.mutation<SystemConfigSaveMutation, SystemConfigSaveMutationVariables>({
      query: (variables) => ({ document: SystemConfigSaveDocument, variables })
    }),
    TelemetryLogs: build.query<TelemetryLogsQuery, TelemetryLogsQueryVariables | void>({
      query: (variables) => ({ document: TelemetryLogsDocument, variables })
    }),
    UserAccessTemplateDelete: build.mutation<UserAccessTemplateDeleteMutation, UserAccessTemplateDeleteMutationVariables>({
      query: (variables) => ({ document: UserAccessTemplateDeleteDocument, variables })
    }),
    UserAccessTemplate: build.query<UserAccessTemplateQuery, UserAccessTemplateQueryVariables>({
      query: (variables) => ({ document: UserAccessTemplateDocument, variables })
    }),
    UserAccessTemplates: build.query<UserAccessTemplatesQuery, UserAccessTemplatesQueryVariables | void>({
      query: (variables) => ({ document: UserAccessTemplatesDocument, variables })
    }),
    UserAccessTemplateSave: build.mutation<UserAccessTemplateSaveMutation, UserAccessTemplateSaveMutationVariables>({
      query: (variables) => ({ document: UserAccessTemplateSaveDocument, variables })
    }),
    UserDelete: build.mutation<UserDeleteMutation, UserDeleteMutationVariables>({
      query: (variables) => ({ document: UserDeleteDocument, variables })
    }),
    UserDetail: build.query<UserDetailQuery, UserDetailQueryVariables>({
      query: (variables) => ({ document: UserDetailDocument, variables })
    }),
    User: build.query<UserQuery, UserQueryVariables>({
      query: (variables) => ({ document: UserDocument, variables })
    }),
    UserInvite: build.mutation<UserInviteMutation, UserInviteMutationVariables>({
      query: (variables) => ({ document: UserInviteDocument, variables })
    }),
    Users: build.query<UsersQuery, UsersQueryVariables | void>({
      query: (variables) => ({ document: UsersDocument, variables })
    }),
    UserAccessDelete: build.mutation<UserAccessDeleteMutation, UserAccessDeleteMutationVariables>({
      query: (variables) => ({ document: UserAccessDeleteDocument, variables })
    }),
    UserAccessSave: build.mutation<UserAccessSaveMutation, UserAccessSaveMutationVariables>({
      query: (variables) => ({ document: UserAccessSaveDocument, variables })
    }),
    UserSave: build.mutation<UserSaveMutation, UserSaveMutationVariables>({
      query: (variables) => ({ document: UserSaveDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAccessRightsQuery, useLazyAccessRightsQuery, useCodeListFirewallsQuery, useLazyCodeListFirewallsQuery, useCodeListGroupsQuery, useLazyCodeListGroupsQuery, useCodeListUserAccessTemplatesQuery, useLazyCodeListUserAccessTemplatesQuery, useConfigQuery, useLazyConfigQuery, useAdminDashboardQuery, useLazyAdminDashboardQuery, useUserDeviceCreateMutation, useUserDeviceDeleteMutation, useUserDeviceSaveNoteMutation, useFirewallDeleteMutation, useGetFirewallQuery, useLazyGetFirewallQuery, useFirewallsQuery, useLazyFirewallsQuery, useSaveFirewallMutation, useGroupDeleteMutation, useGroupQuery, useLazyGroupQuery, useGroupsQuery, useLazyGroupsQuery, useSaveGroupMutation, useMeQuery, useLazyMeQuery, useSecurityLogsQuery, useLazySecurityLogsQuery, useServerAccessQuery, useLazyServerAccessQuery, useServerDeleteMutation, useServerDetailQuery, useLazyServerDetailQuery, useServerQuery, useLazyServerQuery, useServerExpertQuery, useLazyServerExpertQuery, useServersQuery, useLazyServersQuery, useSaveServerMutation, useAadConfigSaveMutation, useCliApiConfigSaveMutation, useCostsUsageQuery, useLazyCostsUsageQuery, useSystemConfigQuery, useLazySystemConfigQuery, useSystemConfigSaveMutation, useTelemetryLogsQuery, useLazyTelemetryLogsQuery, useUserAccessTemplateDeleteMutation, useUserAccessTemplateQuery, useLazyUserAccessTemplateQuery, useUserAccessTemplatesQuery, useLazyUserAccessTemplatesQuery, useUserAccessTemplateSaveMutation, useUserDeleteMutation, useUserDetailQuery, useLazyUserDetailQuery, useUserQuery, useLazyUserQuery, useUserInviteMutation, useUsersQuery, useLazyUsersQuery, useUserAccessDeleteMutation, useUserAccessSaveMutation, useUserSaveMutation } = injectedRtkApi;

